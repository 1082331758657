<template>
  <ul class="nav nav-tabs nav-fill">
    <li class="nav-item">
      <a
        class="nav-link w-100"
        :class="{
          active: !$route.hash || $route.hash == '#review',
        }"
        href="#review"
      >
        Menunggu Diulas
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link w-100"
        :class="{ active: $route.hash == '#history-review' }"
        href="#history-review"
      >
        Riwayat
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ProductReviewProfileNavTab"
};
</script>